<template>
	<div
		class="dropdown-select-wrapper"
		:class="[size, { disabled }, { dark }]"
	>
		<div
			class="input-label"
			v-if="label || $slots['help-text']"
		>
			<TnLabel
				v-if="label"
				:for="uniqueId"
				:size="size"
				:dark="dark"
				>{{ label }}</TnLabel
			>
			<div class="help-text">
				<!-- @slot Slot for displaying help text on the upper right on top of the input field -->
				<slot name="help-text">
					<span v-if="helpText">{{ helpText }}</span>
				</slot>
			</div>
		</div>
		<div class="dropdown-select">
			<input
				class="dropdown-select-item"
				:id="uniqueId"
				:value="selectedItem && selectedItem.label"
				:disabled="disabled"
				v-bind="$attrs"
				@click="showHideDropdown"
				@keyup.enter="handleFocus"
				tabindex="0"
				readonly
				aria-label="selected-item"
				ref="dropdown"
			/>

			<div
				v-if="active && !disabled"
				class="dropdown-select-dropdown"
				:class="[size]"
			>
				<div
					v-for="(item, index) in options"
					:key="index"
					class="dropdown-select-option"
					:class="[
						size,
						{
							'option-focus': optionFocus === item.value,
							'selected-option': selectedOption === item.value,
						},
					]"
					@click="handleClick(item.value)"
					@keyup.enter="handleFocus"
					@keyup.tab="optionFocus = item.value"
					@focusout="optionFocus = null"
					tabindex="0"
				>
					<span>{{ item.label }}</span>
					<TnIcon
						name="success"
						size="m"
						:class="['selected-item-icon', { 'selected-item-icon-visible': selectedOption === item.value }]"
					/>
				</div>
			</div>
			<TnIcon
				:name="icon"
				class="chevron"
				@click="showHideDropdown"
			/>
		</div>
		<div class="support-text">
			<TnSupportText
				v-if="$slots['hint']"
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying hint-text (no icon) via TnSupportText -->
				<slot name="hint"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['error']"
				critical
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying critical/error messages via TnSupportText -->
				<slot name="error"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['warning']"
				warning
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying warning messages via TnSupportText -->
				<slot name="warning"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['info']"
				info
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying informational messages via TnSupportText -->
				<slot name="info"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['success']"
				success
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				<!-- @slot Slot for displaying success messages via TnSupportText -->
				<slot name="success"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="supportText"
				:size="size"
				:disabled="disabled"
				:dark="dark"
			>
				{{ supportText }}
			</TnSupportText>
		</div>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";

export default defineComponent({
	name: "TnDropdownSelect",

	inheritAttrs: false,

	props: {
		/**
		 * Size of the dropdown select
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase());
			},
		},
		/**
		 * The label/text displayed above the select input. @see *slots* for options for customizing all markup
		 */
		label: {
			type: String,
			default: "",
		},
		/**
		 *
		 */
		modelValue: {
			type: String,
			default: "",
		},
		/**
		 * If included as value in options, will set as pre-selected value
		 */
		preSelectedValue: {
			type: String,
			default: "",
		},
		/**
		 * Array of options. Each option is an object with two properties: *label* and *value*
		 */
		options: {
			type: Array,
		},
		/**
		 * Disables the input select and options
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * Support or hint-text for textarea
		 */
		supportText: {
			type: String,
			default: "",
		},
		/**
		 * Help text that shows over the input on the right. Could be used to communicate that input is optional.
		 */
		helpText: {
			type: String,
		},
		dark: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			active: false,
			optionFocus: null,
			selectedOption: "",
		};
	},

	computed: {
		preSelectedItem() {
			return this.options?.find((item) => item.value === this.preSelectedValue);
		},
		selectedItem() {
			return this.options?.find((item) => item.value === this.selectedOption);
		},
		icon() {
			return !this.active ? "chevron-down" : "chevron-up";
		},
		uniqueId() {
			return this.$attrs.id || this.$.uid;
		},
	},

	watch: {
		selectedOption(value) {
			this.$emit("update:modelValue", value);
		},
		value(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.selectedOption = newValue;
			}
		},
	},

	mounted() {
		if (this.preSelectedItem) {
			this.selectedOption = this.preSelectedItem.value;
		}
	},

	methods: {
		showHideDropdown(event) {
			event.stopPropagation();
			if (this.active) window.removeEventListener("click", this.clickOutsideContainer);
			else window.addEventListener("click", this.clickOutsideContainer);
			if (this.active) this.$emit("close");
			return (this.active = !this.active);
		},
		clickOutsideContainer() {
			window.removeEventListener("click", this.clickOutsideContainer);
			this.$emit("close");
			return (this.active = !this.active);
		},
		handleFocus() {
			if (this.optionFocus) {
				this.selectedOption = this.optionFocus;
			}
			this.active = !this.active;
		},
		handleClick(value) {
			this.optionFocus = "";
			this.selectedOption = value;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "sass:math";
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/scss/placeholders";
@use "sass:color";

@function hexToRGB($hex) {
	@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
		color.channel($hex, "blue", $space: rgb);
}

.dropdown-select {
	--tick-opacity: 0;

	position: relative;
	cursor: pointer;
	border-radius: 8px;
	box-sizing: border-box;
	display: inline-flex;
	align-items: center;
	width: 100%;
	box-shadow: 0 0 0 1px var(--dropdown-border-color);
	background-color: var(--dropdown-background-color);

	@extend %font-text-s;

	&:hover {
		box-shadow: 0 0 0 2px var(--dropdown-color-hover);
	}

	&.active {
		box-shadow: 0 0 0 2px var(--dropdown-color-focus);
	}

	&:focus-within {
		box-shadow: 0 0 0 2px var(--dropdown-background-color-focus);
	}

	&-wrapper {
		--dropdown-background-color: #{variables.$color-neutrals-white};
		--dropdown-background-color-disabled: #{variables.$color-neutrals-50-tint};
		--dropdown-background-color-hover: rgba(#{hexToRGB(variables.$color-cta-hover-background)}, 0.08);
		--dropdown-background-color-focus: rgba(#{hexToRGB(variables.$color-cta-focus)}, 1);
		--dropdown-border-color: #{variables.$color-neutrals-200-tint};
		--dropdown-color-hover: #{variables.$color-cta-hover};
		--dropdown-color-focus: #{variables.$color-neutrals-white};
		--dropdown-color-active: #{variables.$color-cta-active};
		--dropdown-color-error: #{variables.$color-critical-500-core};
		--dropdown-color-icon: #{variables.$color-neutrals-500-core};
		--dropdown-color-text: #{variables.$color-primary-dark};
		--dropdown-color-disabled: #{variables.$color-neutrals-400-tint};
		--dropdown-chevron-color: #{variables.$color-neutrals-black};
		--dropdown-color-placeholder: #{variables.$color-neutrals-500-core};

		display: flex;
		flex-direction: column;
		gap: 8px;

		&.dark {
			--dropdown-background-color: #37486b; // rgba(#{hexToRGB(variables.$color-cta-dark-hover-background)}, 0.24);
			--dropdown-background-color-disabled: rgba(#{hexToRGB(variables.$color-cta-dark-disabled)}, 0.18);
			--dropdown-background-color-hover: rgba(#{hexToRGB(variables.$color-cta-dark-hover-background)}, 0.08);
			--dropdown-background-color-focus: rgba(#{hexToRGB(variables.$color-cta-dark-focus)}, 1);
			--dropdown-border-color: #{variables.$color-neutrals-800-shade};
			--dropdown-color-hover: #{variables.$color-cta-dark-hover};
			--dropdown-color-focus: #{variables.$color-primary-dark};
			--dropdown-color-active: #{variables.$color-cta-dark-active};
			--dropdown-color-error: #{variables.$color-critical-800-shade};
			--dropdown-color-icon: #{variables.$color-cta-dark-default};
			--dropdown-color-text: #{variables.$color-neutrals-white};
			--dropdown-color-disabled: #{variables.$color-neutrals-800-shade};
			--dropdown-chevron-color: #{variables.$color-neutrals-white};
			--dropdown-color-placeholder: #{variables.$color-neutrals-200-tint};
		}

		.input-label {
			display: flex;
			justify-content: space-between;
		}

		.selected-option:not(.option-focus) {
			color: var(--dropdown-color-active);
			background: var(--dropdown-background-color-hover);
		}

		&.s {
			gap: 6px;

			input {
				height: 40px;

				@extend %font-text-s;
			}

			.help-text {
				@extend %font-text-xs;
			}

			.selected-option:not(.option-focus) {
				@extend %font-text-bold-s;
			}
		}

		&.m {
			input {
				height: 48px;

				@extend %font-text-m;
			}

			.help-text {
				@extend %font-text-xs;
			}

			.selected-option:not(.option-focus) {
				@extend %font-text-bold-m;
			}
		}

		&.l {
			input {
				height: 56px;

				@extend %font-text-l;
			}

			.help-text {
				@extend %font-text-s;
			}

			.selected-option:not(.option-focus) {
				@extend %font-text-bold-l;
			}

			.input-select-option {
				padding: 14px variables.$spacing-m;
			}
		}

		&.disabled {
			input {
				color: var(--dropdown-color-disabled);
			}

			input::placeholder {
				color: var(--dropdown-color-disabled);
			}

			.chevron {
				fill: var(--dropdown-color-disabled);
			}

			* {
				cursor: not-allowed;
			}
		}

		.support-text {
			display: flex;
			justify-content: flex-start;
			margin-top: 4px;

			div:first-child {
				margin-right: 4px;
			}
		}
	}

	input {
		border-radius: 8px;
		cursor: pointer;
		pointer-events: auto;
		background: none;
		user-select: none;

		&:focus-visible {
			outline: none;
		}

		&::placeholder {
			color: var(--dropdown-color-placeholder);
		}
	}

	.chevron {
		flex-shrink: 0;
		margin-right: variables.$spacing-m;
		display: flex;
		fill: var(--dropdown-chevron-color);
	}

	&-item {
		color: var(--dropdown-color-text);
		min-height: 40px;
		width: 100%;
		box-sizing: border-box;
		padding: 11px variables.$spacing-m;
		border: none;
		outline: none;

		&::placeholder {
			color: var(--dropdown-color-placeholder);
		}
	}

	&-dropdown {
		position: absolute;
		top: 33px;
		width: 100%;
		text-align: left;
		box-sizing: border-box;
		z-index: 2;
		border-radius: 8px;
		box-shadow: 0 0 4px -2px #000;
		margin-top: 4px;
		background-color: var(--dropdown-background-color);
		padding: variables.$spacing-s 0;
		max-height: 300px;
		overflow: auto;

		&.s {
			top: 45px;

			@extend %font-text-s;
		}

		&.m {
			top: 49px;

			@extend %font-text-m;
		}

		&.l {
			top: 57px;

			@extend %font-text-l;
		}
	}

	&-option {
		color: var(--dropdown-color-text);
		cursor: pointer;
		padding: 11px variables.$spacing-l;
		position: relative;
		outline: none;

		&:hover {
			--tick-opacity: 0.3;

			color: var(--dropdown-color-hover);
			background-color: var(--dropdown-background-color-hover);
		}

		&:focus-visible {
			outline: none;
		}

		&.option-focus {
			background-color: var(--dropdown-background-color-focus);
			color: var(--dropdown-color-focus);
		}

		.selected-item {
			&-icon {
				opacity: var(--tick-opacity);
				position: absolute;
				right: variables.$spacing-m;
				top: 50%;
				transform: translateY(-50%);

				&-visible {
					--tick-opacity: 1;
				}
			}
		}
	}
}
</style>
